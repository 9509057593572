(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Banner
     *
     * Usages:
     *   - headerMenu.jsp
     *   - home.jsp
     *
     * Dependencies:
     *   - none
     */
    Vue.component("banner-cta", {
        props: ["i18n"],
        data: function () {
            return {
                text: this.i18n.bannerText,
                cta: this.i18n.bannerCta,
                ctaUrl: this.i18n.bannerCtaUrl,
            };
        },
        template: `
      <div class="banner-cta" ref="bannerCta">
            <div class="banner-cta__title">
              <div class="banner-cta__label">{{i18n.bannerText}}&nbsp;</div>
              <button type="button" class="banner-cta__close-mobile" v-bind:aria-label="i18n.txtClose" @click="close">
                <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg" v-bind:alt="i18n.txtClose"/>
              </button>
            </div>
            
          <div class="banner-cta__dropdown__wrapper"> 
            <a class="wlbutton wlbutton--s wlbutton--sec-green banner-cta__cta" @click="goto">{{i18n.bannerCta}}</a>
            <button type="button" class="banner-cta__close-tablet" v-bind:aria-label="i18n.txtClose" @click="close">
              <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg" v-bind:alt="i18n.txtClose"/>
            </button>
          </div>
      </div>
    `,
        methods: {
            goto: goto,
            close: close,
        },
    });

    const BANNER_CTA_KEY = "banner_cta";
    const EXPIRE_TTL = 1296000000; //15 days
    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function goto() {
        _setWithExpiry(BANNER_CTA_KEY, "enter", EXPIRE_TTL);
        location.href = "/wikiloc/one-percent/poll.do";
    }
    function close() {
        _setWithExpiry(BANNER_CTA_KEY, "close", EXPIRE_TTL);
        this.$refs.bannerCta.remove();
        // Create the event
        var event = new CustomEvent("resize");

        // Dispatch/Trigger/Fire the event
        window.dispatchEvent(event);
    }
    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */
    function _getWithExpiry(key) {
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
    function _setWithExpiry(key, value, ttl) {
        const now = new Date();

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    function _hasToAppearBannerCTA(canSeeTheBanner, destUrl) {
        //check if has already interacted with the banner
        if (_getWithExpiry(BANNER_CTA_KEY)) {
            return false;
        }

        //check time constraints for one percent
        let startDate = Date.parse("2023-03-31");
        let endDate = Date.parse("2023-04-10");
        let actualDate = new Date().getTime();

        let isAllowed = startDate <= actualDate && actualDate <= endDate;
        if (!isAllowed) {
            return false;
        }

        //prevent having two banners, if exists this id, then the component has been loaded
        if (!document.getElementById("vue-language-selector")) {
            return false;
        }

        //we only display to active premium user
        if (!canSeeTheBanner) {
            return false;
        }

        // check if destiny url is the same
        let actualUrl = location.href;
        if (actualUrl.indexOf(destUrl) > -1) {
            return false;
        }

        return true;
    }

    ///////////////////////////////////////////////////////////////////////
    //A Vue Main (check for multiple instances)
    var elementId = "vue-banner-cta";
    if (!document.getElementById(elementId)) {
        return null;
    }

    let destUrl = INJECT_HEADER.i18n.bannerCtaUrl;
    let canSeeTheBanner = INJECT_HEADER.canSeeTheBanner;
    if (_hasToAppearBannerCTA(canSeeTheBanner, destUrl)) {
        new Vue({
            el: "#" + elementId,
            data: {
                i18n: INJECT_HEADER.i18n,
            },
            template: `
			<banner-cta
			  :i18n="i18n"
			>
			</banner-cta>
		`,
        });
    }
})();
